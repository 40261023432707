<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showBack="true"/>
		<AppTitle title="Notifications"/>
		<div class="pt-8 overflow-y-scroll flex-grow">
      <div class="space-y-4">
        <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4">
          <p class="font-bold flex-1">New message</p>
          <div class="flex-0">
            <button type="button" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" role="switch" aria-checked="false">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
          </div>
        </div>
        <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4">
          <p class="font-bold flex-1">New table request</p>
          <div class="flex-0">
            <button type="button" class="bg-green-500 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" role="switch" aria-checked="false">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" class="translate-x-5 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
          </div>
        </div>
        <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4">
          <p class="font-bold flex-1">Friends requests</p>
          <div class="flex-0">
            <button type="button" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" role="switch" aria-checked="false">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
          </div>
        </div>
      </div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
		},
    data(){
      return {
        newTableModalOpen: false
      }
    }
	}
</script>